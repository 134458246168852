import { Avatar, Text, Group, MantineSize } from "@mantine/core";
import { useApi } from "../../context/api.context";
import { useColorInitials } from "../../hooks/helpers.hooks";

export interface IUserAvatarBaseProps {
	showName?: boolean;
	size?: MantineSize;
}

export interface IAdminUserAvatarProps extends IUserAvatarBaseProps {
	userId?: string;
}

export interface IUserAvatarProps extends IUserAvatarBaseProps {
	name?: string;
}

export function AdminUserAvatar(props: IAdminUserAvatarProps) {
	const api = useApi();

	const userName = props.userId === "System"
		? "System"
		: api.adminUsers.GetUserName(props.userId);

	return (
		<UserAvatar
			size={props.size}
			showName={props.showName}
			name={userName}
		/>
	);
}

export function UserAvatar(props: IUserAvatarProps) {
	const colorInitials = useColorInitials(props.name);

	return (
		<Group noWrap>
			<Avatar
				color={colorInitials.color}
				variant={"light"}
				radius={"xl"}
				size={props.size}
				alt={props.name}
			>
				{colorInitials.initials}
			</Avatar>
			{
				props.showName &&
				<Text truncate>{props.name || "-"}</Text>
			}
		</Group>
	);
}